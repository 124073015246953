var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Text } from 'evolux-ui-v2';
import { useI18n } from '../../commons/useI18n';
import MessageContent from './MessageContent/MessageContent';
import { LiveChatContext, LiveChatMutationsContext, } from '../../contextProviders/LiveChatContextProvider';
import { buildDisplayTime } from '../../utils/utils';
import { DateTime } from 'luxon';
import ReplyPreview from '../CurrentSession/ReplyPreview';
import DoubleCheckmark from '../../svgIcons/double-checkmark.svg';
// @ts-ignore
var MESSAGE_REPLY = (_a = window === null || window === void 0 ? void 0 : window.MESSAGE_REPLY) !== null && _a !== void 0 ? _a : true;
var MessageWrapperElement = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  word-break: break-word;\n  justify-content: ", ";\n  flex-direction: ", ";\n\n  &:hover {\n    .reply-button {\n      visibility: visible;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  word-break: break-word;\n  justify-content: ", ";\n  flex-direction: ", ";\n\n  &:hover {\n    .reply-button {\n      visibility: visible;\n    }\n  }\n"])), function (_a) {
    var sentByContact = _a.sentByContact;
    return (sentByContact ? 'end' : 'start');
}, function (_a) {
    var sentByContact = _a.sentByContact;
    return (sentByContact ? 'row-reverse' : 'row');
});
var MessageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: ", ";\n  max-width: 70%;\n\n  article {\n    box-sizing: border-box;\n    margin-bottom: 10px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: ", ";\n  max-width: 70%;\n\n  article {\n    box-sizing: border-box;\n    margin-bottom: 10px;\n  }\n"])), function (_a) {
    var sentByContact = _a.sentByContact;
    return (sentByContact ? 'end' : 'start');
});
var SentByUserWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n  border-radius: 16px 16px 16px 2px;\n  padding: 8px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n  border-radius: 16px 16px 16px 2px;\n  padding: 8px;\n"])), function (props) { return (props.isInteractive ? 'unset' : '#f3f3f3'); });
var SentByContactWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: fit-content;\n  flex-direction: column;\n  background-color: var(--primaryLight);\n  border-radius: 16px 16px 2px 16px;\n  opacity: ", ";\n  padding: 8px;\n"], ["\n  display: flex;\n  width: fit-content;\n  flex-direction: column;\n  background-color: var(--primaryLight);\n  border-radius: 16px 16px 2px 16px;\n  opacity: ", ";\n  padding: 8px;\n"])), function (_a) {
    var transparent = _a.transparent;
    return (transparent ? '0.5' : '1');
});
var TimeAndStateContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: end;\n  align-items: center;\n  gap: 2px;\n"], ["\n  display: flex;\n  justify-content: end;\n  align-items: center;\n  gap: 2px;\n"])));
var StyledTime = styled.small(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  min-width: fit-content;\n  display: inline-flex;\n  align-items: flex-end;\n  color: #686667;\n  font-size: 12px;\n  float: right;\n  margin-left: 5px;\n  margin-right: 3px;\n  line-height: 18px;\n  align-self: flex-end;\n"], ["\n  min-width: fit-content;\n  display: inline-flex;\n  align-items: flex-end;\n  color: #686667;\n  font-size: 12px;\n  float: right;\n  margin-left: 5px;\n  margin-right: 3px;\n  line-height: 18px;\n  align-self: flex-end;\n"])));
var BlueDoubleCheckmark = styled(DoubleCheckmark)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  filter: invert(79%) sepia(10%) saturate(6169%) hue-rotate(168deg) brightness(94%) contrast(96%);\n"], ["\n  filter: invert(79%) sepia(10%) saturate(6169%) hue-rotate(168deg) brightness(94%) contrast(96%);\n"])));
var ReplyButton = styled(Button)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  visibility: hidden;\n"], ["\n  visibility: hidden;\n"])));
function InnerMessage(_a) {
    var message = _a.message, children = _a.children;
    var i18n = useI18n();
    var sendMessageToAPI = React.useContext(LiveChatMutationsContext).sendMessageToAPI;
    var isMessagePending = ['pending', 'sending', undefined].includes(message.state);
    var isMessageFailed = message.state === 'failed';
    var handleSendToAPI = function () {
        if (message.interactive_content) {
            console.error('Attempted to send an invalid message type:', message);
        }
        else {
            sendMessageToAPI(message);
        }
    };
    if (message.author === 'CONTACT') {
        return (React.createElement(React.Fragment, null,
            React.createElement(SentByContactWrapper, { transparent: isMessagePending || isMessageFailed }, children),
            (isMessagePending || isMessageFailed) && (React.createElement("div", null, isMessagePending ? (React.createElement(Text, { size: "sm", transparent: true }, i18n._('Sending...'))) : (React.createElement(React.Fragment, null,
                React.createElement(Text, { size: "sm", transparent: true }, i18n._('Sent failed. ')),
                React.createElement(Text, { size: "sm", type: "link", href: "#", onClick: handleSendToAPI }, i18n._('Click to retry'))))))));
    }
    return (React.createElement(SentByUserWrapper, { isInteractive: !!message.interactive_content }, children));
}
var MessageStateIcon = function (_a) {
    var messageState = _a.messageState;
    if (messageState === 'read') {
        return React.createElement(BlueDoubleCheckmark, { width: 14, height: 14 });
    }
    if (messageState === 'sent') {
        return React.createElement(Icon, { type: "checkmark", size: "sm", color: "positive", "aria-hidden": true });
    }
    if (messageState === 'failed') {
        return React.createElement(Icon, { type: "exclamation", color: "negative", size: "sm", "aria-hidden": true });
    }
    return React.createElement(Icon, { type: "clock", size: "sm", transparent: true, "aria-hidden": true });
};
function MessageComponent(_a) {
    var _b, _c;
    var message = _a.message, setMessageBeingReplied = _a.setMessageBeingReplied;
    var i18n = useI18n();
    var messages = React.useContext(LiveChatContext).messages;
    var handleReplyClick = function () {
        setMessageBeingReplied(message);
        var host = document.getElementById('tooke-live-chat-container');
        if (host && host.shadowRoot) {
            var messageInput = host.shadowRoot.getElementById('message-input');
            if (messageInput) {
                messageInput.focus();
            }
        }
    };
    var isIceBreakerMessage = !!(((_b = message.interactive_content) === null || _b === void 0 ? void 0 : _b.interactive.type) === 'iceBreakers');
    var isInteractive = !!message.interactive_content;
    var replyToMessage = messages.find(function (m) { return m.uuid === message.reply_to_uuid; });
    return (React.createElement(MessageWrapperElement, { id: message.uuid, isInteractive: isInteractive, sentByContact: message.author === 'CONTACT' },
        React.createElement(MessageContainer, { sentByContact: message.author === 'CONTACT' },
            React.createElement(InnerMessage, { message: message },
                MESSAGE_REPLY && replyToMessage && React.createElement(ReplyPreview, { message: replyToMessage }),
                React.createElement(MessageContent, { message: message }),
                React.createElement(TimeAndStateContainer, null,
                    React.createElement(StyledTime, null, buildDisplayTime(DateTime.fromISO((_c = message.when) !== null && _c !== void 0 ? _c : ''))),
                    message.author === 'CONTACT' && React.createElement(MessageStateIcon, { messageState: message.state })))),
        MESSAGE_REPLY && !isIceBreakerMessage && (React.createElement(ReplyButton, { className: "reply-button", "aria-label": i18n._('Reply'), shape: "circle", variant: "tertiary", icon: React.createElement(Icon, { type: "arrow-circle", "aria-hidden": true }), onClick: handleReplyClick }))));
}
export default MessageComponent;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
