export var parseMessage = function (originalMessage) {
    var matchedUrls = {};
    var parsedMessage = originalMessage.split(/[\s,]+/).reduce(function (acc, word) {
        if (!matchedUrls[word]) {
            try {
                var url = new URL(word);
                if (!url.host || !url.hostname || !url.origin) {
                    throw new TypeError();
                }
                matchedUrls[word] = true;
                var escapedLink = word
                    .replace(/\?/g, '\\?')
                    .replace(/\[/g, '\\[')
                    .replace(/\(/g, '\\(')
                    .replace(/\)/g, '\\)')
                    .replace(/\*/g, '\\*')
                    .replace(/\+/g, '\\+')
                    .replace(/\$/g, '\\$')
                    .replace(/\#/g, '\\#');
                return acc.replace(new RegExp("".concat(escapedLink), 'g'), "[".concat(word, "](").concat(word, ")"));
            }
            catch (e) {
                return acc;
            }
        }
        return acc;
    }, originalMessage);
    return parsedMessage;
};
